import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { API_URL, VERSION } from "../../environments/environment";
import { Observable, from, lastValueFrom } from "rxjs";
import { Auth, User } from '../01-models';
import { map } from "rxjs/operators";
import { StorageService } from "./storage.service";
import { AuthService } from "./auth.service";
import { PrivacyPolicies } from "../01-models/privacy-policies";
import { Rol } from "../01-models/rol";

const USERS_KEY = 'users';
const PRIVACY_POLICIES_KEY = 'privacyPolicies';

@Injectable({
  providedIn: "root"
})
export class UserService {
  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private authService: AuthService
    ) {}

  getUsers(): Observable<User[]> {
    const url = `${API_URL}/${VERSION}/user/users`;
    return this.http.get<User[]>(url).pipe(
      map(users => {
        this.storageService.set(USERS_KEY, users);

        /*const user = this.authService.getUser();

        const userRemote = users.find(u => u.id === user.id);

        if (userRemote) {
          this.authService.setUser(userRemote);
        }*/

        return users;
      })
    );
  }

  getMe(): Observable<Auth> {
    const url = `${API_URL}/${VERSION}/auth/getMe`;
    return this.http.post<Auth>(url, {}).pipe(
      map(auth => {
        this.authService.setUser(auth);
        return auth;
      })
    );
  }

  getUserById(userId: number): Observable<User | undefined> {
    return from(this.storageService.get(USERS_KEY)).pipe(
      map((users: User[]) => users.find(user => user.id == userId))
    );
  }

  getUserByEmail(email: string): Observable<User | undefined> {
    return from(this.storageService.get(USERS_KEY)).pipe(
      map((users: User[]) => users.find(user => user.email == email))
    );
  }

  getUserByDni(workerDni: string): Observable<User | undefined> {
    return from(this.storageService.get(USERS_KEY)).pipe(
      map((users: User[]) => users.find(user => workerDni && user.userDni == workerDni.toUpperCase()))
    );
  }

  getUsersByDni(workerDnis: string[]): Observable<User[]> {
    return from(this.storageService.get(USERS_KEY)).pipe(
      map((users: User[]) => {
        workerDnis = workerDnis.map(workerDni => workerDni && workerDni.toUpperCase());
        return users.filter(user => user.userDni && workerDnis.includes(user.userDni));
      })
    );
  }

  getWorkers(): Observable<User[]> {
    return from(this.storageService.get(USERS_KEY)).pipe(
      map((users: User[]) => users.filter(user => user.assignedRoles.some(role => role.rolName !== 'Cliente')))
    );
  }

  getAllUsers(): Observable<User[]> {
    return from(this.storageService.get(USERS_KEY));
  }

  update(user: User, password?: any) {
    const url = `${API_URL}/${VERSION}/user/update`;
    this.updateUser(user);
    return this.http.post(url, password ? {...user, password} : user);
  }

  updateUser(editedUser: User) {
    this.storageService.get(USERS_KEY).then((users: User[]) => {
      if (!users) {
        throw new Error('Users not found: ' + JSON.stringify(editedUser));
      }
      const index = users.findIndex(user => user.id === editedUser.id);
      users[index] = editedUser;
      this.storageService.set(USERS_KEY, users);
    });
  }

  userDelete(userId: number) {
    const url = `${API_URL}/${VERSION}/user/userDeleteLogic/${userId}`;
    this.storageService.get(USERS_KEY).then((users: User[]) => {
      const index = users.findIndex(user => user.id === userId);
      users.splice(index, 1);
      this.storageService.set(USERS_KEY, users);
    });
    return this.http.post(url, {});	
  }

  setPoliciyPrivacy(permissions: PrivacyPolicies) {
    this.storageService.set(PRIVACY_POLICIES_KEY, permissions);
  }

  getPoliciyPrivacy(): Observable<PrivacyPolicies> {
    return from(this.storageService.get(PRIVACY_POLICIES_KEY));
  }

  getPoliciyPrivacyPermanent(): Observable<PrivacyPolicies> {
    return from(this.storageService.getStoragePermanentConfig(PRIVACY_POLICIES_KEY));
  }

  setPoliciyPrivacyPermanent(permissions: boolean) {
    return this.storageService.setStoragePermanentConfig(PRIVACY_POLICIES_KEY, permissions);
  }

  assignRoleToUser(userId: number, roleId: number, tag: string) {

    const url = `${API_URL}/${VERSION}/user/assignRoleToUser`;
    return lastValueFrom(this.http.post(url, {userId, roleId, tag}));
  }

  editTagRoleToUser(userId: number, roleId: number, tag: string) {
    const url = `${API_URL}/${VERSION}/Roles/edit-role-assigned-tag`;
    return lastValueFrom(this.http.post(url, {userId, roleId, tag}));
  }

  removeRoleToUser(userId: number, rolId: number) {
    const url = `${API_URL}/${VERSION}/user/removeRoleFromUser/${userId}/${rolId}`;
    return lastValueFrom(this.http.post(url, {}));
  }





}
